.navbar-light .navbar-nav .nav-link {
  color: $primary;
}

// .navbar-dark .navbar-toggler-icon {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%228,105,0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 165, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-light .navbar-toggler {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
}

@media (max-width: 992px) {
  .navbar-nav {
    border: 0;

    .dropdown-menu {
      border-radius: 0;
      border: 0;

      .dropdown-item {
        border-bottom: 1px solid #ddd;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .dropdown-item:last-child {
        border-bottom: 0;
      }

      .dropdown-item:hover {
        background-color: #333;
        color: white;
      }
    }
  }
}
