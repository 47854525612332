.lungs-logo-home {
  width: 100%;
  max-width: 444px;
}

.home-row {
  margin: 0 5px;
}

.home-content {
  height: fit-content;
  border-left: solid 10px #e02429;
  padding-left: 30px;

  h1 {
    font-family: roboto, sans-serif !important;
    color: #e02429 !important;
    font-weight: 700;
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h2 {
    color: #333840 !important;
    font-weight: 700;
    font-size: 27px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    color: #616161;
    font-size: 13px;
    line-height: 22px;
    font-weight: 300;
  }
}

.event-link {
  border: 0 !important;
  color: #fff !important;
  background: $color-dark-secondary;
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
  transition: 0.5s;
  font-size: 14px;
  margin-top: 20px;

  @media (max-width: 992px) {
    padding: 10px;
  }

  &:hover {
    background: $color-dark-primary;
    text-decoration: none;
  }
}

.event-card-container {
  padding-top: 20px;
}

.modal-iframe {
  width: 100%;
  height: 70vh;
  border: none;
}

body.live-event {
  iframe {
    border: none;
  }

  .bottom-head {
    display: none;
  }

  .page {
    overflow: hidden;
    padding-bottom: 0;
  }

  .big-head {
    min-height: unset;
    position: absolute;
    z-index: 110;
    width: 100%;
    left: 0;
    background-color: #fff !important;
    // background-color: rgba(255, 255, 255, 0.6) !important;
    padding: 0;
  }

  @media (max-width: 900px) {
    .big-head {
      max-height: 50px;
    }

    .hamburger-icon {
      font-size: 1.3em !important;
    }

    .main-logo {
      height: 40px !important;
    }

    .navbar-brand {
      padding-top: 0 !important;
    }
  }

  .next-stage-button {
    // font-size: 40px !important;
  }

  .next-stage-container:before {
    content: "";
    background: #000;
    width: 70px;
    height: 70px;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    border-radius: 100px;
  }

  .next-stage-text {
    background-color: #666;
    line-height: 1.7;
    position: absolute;
    width: 110px;
    font-size: 12px;
    right: -35px;
    bottom: -35px;
    border-radius: 2px;
  }

  .header-banner {
    display: none;
  }
}

.home-description {
  font-size: 1.2em;
  line-height: 1.8em;
  margin-bottom: 20px;
}
