body {
  background-color: #005777;
  color: #e3d3c3;
  font-family: dosis, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.jumbotron {
  width: 100%;
  img {
    width: 100%;
  }
}

h1,
.nav-link,
.font-roy,
.circle-link {
  font-family: 'Optima', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  // text-align: center;
}

.App-header {
  background-color: #e3d3c3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
}

.enter-site-link {
  width: 50vw;
}

.App-link {
  color: #61dafb;
}

.navbar {
  background-color: #e3d3c3 !important;
  justify-content: center;
}

.nav-title {
  font-size: 40px;
  color: #e46900;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 40px;

  @media (min-width: 992px) {
    font-size: 60px;
    line-height: 50px;
  }
}

h1 {
  font-size: 100px;
  color: #311400;
  margin-bottom: 0px;
  margin-top: 5rem;
  line-height: 100px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: center;
  }
}

.navbar-toggler {
  border: 1px solid #fff;
  border-color: #fff;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}

@media (max-width: 992px) {
  .menu-divider {
    display: none;
  }
}

.menu-divider {
  padding-top: 0.4rem;
  margin-bottom: 0;
}

@mixin on-circle($item-count, $circle-size, $item-width, $item-height) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-width;
    height: $item-height;
    margin: -($item-height / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

h2 {
  color: #e46900;
}

i {
  color: rgb(12, 5, 0);
}

hr {
  background-color: #e46900;
  margin: 2rem auto;
  opacity: 1;
  height: 1px;
  width: 50%;
}

.press-menu {
  text-align: center;
  color: rgb(12, 5, 0);
  font-size: 24px;

  a {
    color: rgb(12, 5, 0);
    font-size: 24px;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .header-icon-container {
    .social-icon-container {
      // background: rgba(0, 0, 0, 0.6);
      height: 60px;
      width: auto;
      margin: 0 auto;

      .social-icon {
        color: #ffdfc3;
        font-size: 30px;
      }
    }
  }
}

.w-100 {
  width: 100% !important;
}

.social-icon-container {
  // background: rgba(0, 0, 0, 0.6);
  height: 60px;
  width: 60px;
  border-radius: 37px;
  line-height: 72px;
  /* padding-top: 1px; */
  margin: 0 1rem;
  text-align: center;

  &.no-bg {
    background: unset;
  }
}

.video-iframe {
  iframe {
    border-radius: 12px;
  }
}

.social-icon {
  text-decoration: none;

  color: #ffdfc3;
  &.light {
    color: #ffdfc3;
  }
  text-shadow: 2px 2px 5px #0a0a0a;
  font-size: 40px;
  line-height: 4rem;
  text-align: center;
}

.circle-link {
  list-style: none;
  text-align: center;
  a {
    text-decoration: none;
    color: #ffdfc3;
    text-shadow: 2px 2px 5px #0a0a0a;
    font-size: 36px;
    line-height: 1.5rem;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .circle-container {
    @include on-circle(
      $item-count: 8,
      $circle-size: 20em,
      $item-width: 12em,
      $item-height: 6em
    );
    margin-top: 4rem;
    // border: solid 5px tomato;

    img {
      display: block;
      max-width: 100%;
      border-radius: 50%;
      filter: grayscale(100%);
      // border: solid 5px tomato;
      transition: 0.15s;

      &:hover,
      &:active {
        filter: grayscale(0);
      }
    }
  }

  .circle-link {
    a {
      line-height: 1rem;
      font-size: 40px;
    }
  }
}

.youtube-embed {
  width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.album-preview {
  width: 100%;
  position: relative;

  .album-art {
    width: 100%;
  }

  .album-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    display: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  &:hover,
  &:active {
    .album-overlay {
      display: flex;

      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
