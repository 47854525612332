// Bootstrap theme overrides
// Color system

// Basic colors
$white: #fff;
$gray-100: #f8f8f8;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #ececec;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// Brand or theme Specific colors should be added here
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #e46900;
$yellow: #f59e00;
$green: #128a14;
$teal: #20c997;
$cyan: #0dcaf0;
$darkblue: #0f3a7a;

$color-dark-primary: #e46900;
$color-dark-secondary: #de7837;
$color-light-primary: #d6d6d6;
$color-light-secondary: #4aafe2;

$text-color-primary: $color-dark-primary;
$text-color-light: $color-light-primary;

// Define theme descriptors (see $example on how to add additional)
$primary: $color-dark-primary;
$secondary: $gray-500;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: #d2d2d2;
$highlight: $color-dark-secondary;

$body-color: #525454;
$font-family-sans-serif: Trebuchet MS, Lucida Grande, Lucida Sans Unicode,
  Lucida Sans, Tahoma, sans-serif;

.color-primary {
  color: $primary;
}
