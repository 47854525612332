.bd-lg-right {
  @media (min-width: 992px) {
    border-right: 1px solid #fff;
  }
}

.bd-none {
  border: 0 !important;
}

.text-body {
  color: $body-color;
}

.text-dark-grey {
  color: #494948 !important;
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 900px;
  }
}

.form-checkbox span a {
  color: #494948 !important;
  text-decoration: underline !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $primary;
}

p {
  font-size: 1rem;

  @media (min-width: 992px) {
    font-size: 1.25rem;
  }
}

a {
  color: white;
}
