.footer {
  background-color: #fff;
  border-top: solid 1px #ccc;
  padding-top: 15px;
  padding-bottom: 20px;
  min-height: 30px;

  h4 {
    color: #494948 !important;
    font-weight: 700;
    font-size: 16px;
    margin: 20px 0;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.8em;
    font-size: 16px !important;
    font-weight: 400;

    small {
      line-height: 12px !important;
    }
  }

  a {
    color: #525454 !important;
  }
}

a {
  word-wrap: break-word;
}
